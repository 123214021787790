<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="prt-card-header">
                <div class="header-naming">
                  <CIcon name="cil-sitemap" />
                  Social Media Beiträge
                </div>
                <div class="header-button">
                  <CButton
                    to="socialMedia/post/new"
                    name="backagain"
                    size="sm"
                    key="key"
                    color="dark"
                  >
                    <CIcon size="sm" name="cil-plus" />
                    <span> Neuen Beitrag erstellen</span>
                  </CButton>
                </div>
              </div>
            </slot>
          </CCardHeader>
          <CCardBody v-if="!loading">
            <div id="calendar"></div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      show.sync="showCreatePostModal"
      @ok="createPost"
      title="Neuen Beitrag erstellen?"
    >
      <template #modal-header>
        <h5 class="modal-title">Neuen Beitrag erstellen?</h5>
        <CButton
          class="close"
          aria-label="Close"
          @click="showCreatePostModal = false"
        >
          <span aria-hidden="true">&times;</span>
        </CButton>
      </template>
      <template #modal-body>
        <p>Möchten Sie einen neuen Beitrag an diesem Tag erstellen?</p>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from 'axios';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import deLocale from '@fullcalendar/core/locales/de';
import { CCard, CCardBody, CCardHeader, CRow, CCol, CButton, CModal, CIcon } from '@coreui/vue';

export default {
  name: 'SocialMediaPosts',
  components: {
    CCard, CCardBody, CCardHeader, CRow, CCol, CButton, CModal, CIcon
  },
  data() {
    return {
      loading: false,
      calendarEvents: [],
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      selectedDate: null,
      showCreatePostModal: false,
    };
  },
  mounted() {
    this.loadSocialMediaPosts();
  },
  methods: {
    loadSocialMediaPosts() {
      axios
        .get('/socialMedia/posts')
        .then(response => {
          if (Array.isArray(response.data)) {
            this.calendarEvents = response.data.map(post => ({
              id: post.id,
              title: post.name,
              start: post.publishAt,
              extendedProps: {
                network: post.connection.socialMedia,
                connection: post.connection,
                imageUrl: post.imageUrl,
                content: post.content // Hier das content hinzufügen
              }
            }));
            console.log('Events loaded:', this.calendarEvents);
            this.initializeCalendar();
          } else {
            console.error('Unexpected response data format:', response.data);
          }
        })
        .catch(err => {
          console.error('Problem while fetching social media posts', err);
          this.loading = false;
        });
    },
    initializeCalendar() {
      const calendarEl = document.getElementById('calendar');
      const calendar = new Calendar(calendarEl, {
        plugins: this.plugins,
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'timeGridWeek,dayGridMonth'
        },
        events: this.calendarEvents,
        locales: [deLocale], 
        locale: 'de',
        editable: true,
        droppable: true,
        selectable: true,
        dateClick: this.handleDateClick, // Fügt das Klick-Ereignis hinzu
        eventDurationEditable: true,
        eventContent: function(arg) {
          let arrayOfDomNodes = [];
          
          // Badge für das Netzwerk
          let networkBadge = document.createElement('span');
          networkBadge.classList = `badge badge-${this.getNetworkBadgeColor(arg.event.extendedProps.network)}`;
          networkBadge.innerText = arg.event.extendedProps.network;
          networkBadge.style.display = 'block';
          networkBadge.style.padding = '0.25em 0.4em';
          networkBadge.style.fontSize = '75%';
          networkBadge.style.fontWeight = '700';
          networkBadge.style.lineHeight = '1';
          networkBadge.style.textAlign = 'center';
          networkBadge.style.whiteSpace = 'nowrap';
          networkBadge.style.verticalAlign = 'baseline';
          networkBadge.style.borderRadius = '0.25rem';
          networkBadge.style.marginBottom = '5px';
          
          // Titel des Events
          let titleEvent = document.createElement('div');
          if(arg.event._def.title) {
            titleEvent.innerHTML = arg.event._def.title;
            titleEvent.classList = "fc-event-title fc-sticky";
            titleEvent.style.fontSize = '13px';
            titleEvent.style.textAlign = 'center';
            titleEvent.style.margin = '5px 0';
            titleEvent.style.display = 'block';
            titleEvent.style.width = '100%';
          }
          
          // Bild des Events
          let imgEventWrap = document.createElement('div');
          if(arg.event.extendedProps.imageUrl) {
            let imgEvent = `<img src="${arg.event.extendedProps.imageUrl}" class="event-image" style="width: 100%; height: auto; max-height: 100px; object-fit: contain;" />`;
            imgEventWrap.classList = "fc-event-img";
            imgEventWrap.innerHTML = imgEvent;
            imgEventWrap.style.width = '100%';
            imgEventWrap.style.maxWidth = '100%';
            imgEventWrap.style.overflow = 'hidden';
            imgEventWrap.style.borderRadius = '4px';
            imgEventWrap.style.marginTop = '5px';
            imgEventWrap.style.display = 'block';
          }
          
          arrayOfDomNodes = [networkBadge, titleEvent, imgEventWrap];
          
          return { domNodes: arrayOfDomNodes };
        }.bind(this),
        eventDidMount: function(info) {
          if (info.event.extendedProps.imageUrl) {
            info.el.querySelector('.event-image').style.maxHeight = '100px';
            info.el.querySelector('.event-image').style.width = '100%';
            info.el.querySelector('.event-image').style.objectFit = 'contain';
          }
          // Entferne Flex-Eigenschaften, die von FullCalendar hinzugefügt wurden
          info.el.style.display = 'block';
        },
        eventClick: this.handleEventClick,
        eventDrop: this.handleEventDrop,
        eventResize: this.handleEventResize
      });
      calendar.render();
    },
    handleDateClick(info) {
      this.selectedDate = info.dateStr;
      this.showCreatePostModal = true;
    },
    createPost() {
      this.$router.push(`/socialMedia/post/new?date=${this.selectedDate}`);
    },
    handleEventClick(info) {
      this.$router.push(`/socialMedia/post/edit/${info.event.id}`);
    },
    handleEventDrop(info) {
      const updatedPost = {
        id: info.event.id,
        connectionId: info.event.extendedProps.connection.id,
        name: info.event.title,
        publishAt: info.event.start.toISOString(),
        content: info.event.extendedProps.content,
        imageUrl: info.event.extendedProps.imageUrl
      };

      console.log('Updating post with data:', updatedPost);

      axios
        .put(`/socialMedia/posts/${info.event.id}`, updatedPost)
        .then(() => {
          this.$toaster.makeToast('Erfolgreich!', 'Der Beitrag wurde erfolgreich verschoben.');
          this.loadSocialMediaPosts();
        })
        .catch(err => {
          console.error('Fehler beim Aktualisieren des Beitrags:', err);
          if (err.response) {
            console.log('Server response:', err.response.data);
          }
          console.log('Request failed with data:', updatedPost);
          this.$toaster.makeToast('Fehler!', 'Der Beitrag konnte nicht verschoben werden. Bitte versuchen Sie es später erneut.');
        });
    },
    handleEventResize(info) {
      const updatedPost = {
        connectionId: info.event.extendedProps.connection.id,
        name: info.event.title,
        publishAt: info.event.start.toISOString(),
        content: info.event.extendedProps.content,
        imageUrl: info.event.extendedProps.imageUrl
      };

      axios
        .put(`/socialMedia/posts/${info.event.id}`, updatedPost)
        .then(() => {
          this.$toaster.makeToast('Erfolgreich!', 'Der Beitrag wurde erfolgreich geändert.');
          this.loadSocialMediaPosts();
        })
        .catch(err => {
          console.error('Fehler beim Aktualisieren des Beitrags:', err);
          if (err.response) {
            console.log('Server response:', err.response.data);
          }
          this.$toaster.makeToast('Fehler!', 'Der Beitrag konnte nicht geändert werden. Bitte versuchen Sie es später erneut.');
        });
    },
    getNetworkBadgeColor(network) {
      switch (network) {
        case 'Facebook':
          return 'primary';
        case 'Instagram':
          return 'danger';
        case 'LinkedIn':
          return 'info';
        default:
          return 'secondary';
      }
    }
  }
};
</script>

<style scoped>
/* Beispielhafte Anpassungen für das Styling */
.fc-event-title {
  font-size: 14px;
  text-align: center;
}

.fc-event-img {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  border-radius: 4px;
  margin-top: 5px;
  display: block;
}

.fc-event .fc-event-time, .fc-event .fc-event-title {
  padding: 0 1px;
  white-space: normal;
  overflow: hidden;
  color: #fff;
}
</style>
